body {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1f1f1f;
  color: white;
  text-align: center;
}

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300&family=Teko:wght@300;500&display=swap");

.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.wrapper form {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -webkit-box-reflect: below 1px linear-gradient(transparent, #5fb4ff27);
  line-height: 0.1em;
  outline: none;
  animation: lightFlimmer 3s linear infinite;
  margin-bottom: 10%;
}

.logoTitle {
  position: relative;
  bottom: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: "Teko", sans-serif;
  letter-spacing: 6px;
  width: 200px;
  color: #a0a0a0;
  margin-top: 5px;
}

.logo {
  position: relative;
  bottom: -12px;
  font-size: 3.5rem;
  color: #a0a0a0;
  animation: lightFlimmer 3s linear infinite;
  margin-top: 6%;
}

@keyframes lightFlimmer {
  0%,
  18%,
  20%,
  24.1%,
  50.1%,
  60%,
  65.1%,
  80%,
  90.1%,
  92% {
    color: #a0a0a0;
    text-shadow: none;
  }
  18.1%,
  20.1%,
  24%,
  30%,
  50%,
  60.1%,
  80.1%,
  90%,
  92.1%,
  100% {
    color: #5fb4ff;
    text-shadow: 0 0 10px #5fb4ff, 0 0 20px #5fb4ff, 0 0 40px #5fb4ff,
      0 0 80px #5fb4ff, 0 0 160px #5fb4ff;
  }
}

.promptContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  margin-top: 7%;
}

.wrapper form img {
  max-width: 30px;
  max-height: 30px;
}

.wrapper form textarea {
  width: 600px;
  height: 300px;
  max-width: 100%;
  background-color: black;
  color: white;
  border: 1px solid #464646;
  font-size: 22px;
  font-family: "IBM Plex Mono", monospace;
  padding: 10px 15px;
  flex: 1;
  border-radius: 10px;
  box-shadow: 0 0 10px #5fb4ff;
  transition: 0.5s ease;
  outline: none;
}

.wrapper form textarea:focus {
  transition: 0.5s ease;
  background-color: #464646;
  box-shadow: inset 0 0 10px black;
}

.wrapper form button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 60px;
  border: 3px solid #464646;
  font-size: 32px;
  font-family: "Teko", sans-serif;
  padding: 5px 20px;
  background-color: #7a7a7a;
  color: #464646;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.5s ease-in-out;
  box-shadow: 0 0 5px rgb(173, 173, 173), inset 0 0 10px rgb(0, 0, 0);
  margin-top: 20px;
}

.wrapper form button:hover {
  transition: 0.5s ease-in-out;
  opacity: 0.8;
  text-shadow: 0 0 3px #5fb4ff;
  box-shadow: 0 0 10px #5fb4ff, inset 0 0 10px #5fb4ff;
}

.response-area {
  font-size: 20px;
  width: 60%;
  max-width: 100%;
  display: block;
  margin: 50px auto 0 auto;
  font-family: "Teko", sans-serif;
  font-weight: 200;
  line-height: 1.5em;
}

.footer {
  font-size: 14px;
  letter-spacing: 4px;
  margin: 30px 0 40px 0;
  opacity: 0.5;
}

.cg-logo.loading {
  animation: loadingspin 2s infinite linear;
}

@keyframes loadingspin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media screen and (max-width: 1100px) {
  .logoContainer {
    margin-bottom: 12%;
  }

  .promptContainer {
    width: 500px;
  }

  .wrapper form textarea {
    font-size: 20px;
  }

  .response-area {
    font-size: 20px;
    width: 60%;
  }
}

@media screen and (max-width: 550px) {
  .logoContainer {
    margin-bottom: 25%;
  }

  .promptContainer {
    width: 300px;
  }

  .wrapper form textarea {
    font-size: 18px;
  }

  .response-area {
    font-size: 20px;
    width: 60%;
  }
}

@media screen and (max-width: 350px) {
  .logoContainer {
    margin-bottom: 30%;
  }

  .promptContainer {
    width: 250px;
  }

  .wrapper form textarea {
    font-size: 16px;
  }

  .response-area {
    font-size: 20px;
    width: 60%;
  }
}
